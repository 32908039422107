import { createContext, useState, useEffect, useMemo, useContext } from 'react';
import { ProviderProps, AccountsContextType, Ou } from '../interfaces';
import { getAccounts, getRegions } from '../services/CommonService';
import { useUser } from './UserContext';


const defaultValue: AccountsContextType = {
  accounts: [],
  regions: [],
  account: undefined,
  region: undefined,
  loading: false,
  reloadAccounts: () => {},
  setAccount: () => {},
  setRegion: () => {}
}

export const AccountsContext = createContext<AccountsContextType>(defaultValue);

export const AccountsProvider = ({children}: ProviderProps) => {

  const user = useUser();

  const [accounts, setAccounts] = useState<Ou[]>([]);
  const [regions, setRegions] = useState<string[]>([]);
  const [account, setAccount] = useState<string>();
  const [region, setRegion] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  // Get data
  const reloadAccounts = () => {
    setLoading(true);
    Promise.all([getAccounts(), getRegions()])
      .then(responses => {
        setAccounts(responses[0].data);
        setRegions(responses[1].data);
      })
      .catch(() => {})
      .then(() => setLoading(false))
  }

  // Fetch data if user is logged in
  useEffect(() => {
    if (user?.user) reloadAccounts();
  }, [user?.user]);

  const values = useMemo(() => ({ accounts, regions, reloadAccounts, account, region, setAccount, setRegion, loading }), [accounts, regions, account, region, loading]);

  // make sure other components can read this value
  return (<AccountsContext.Provider value={values}>{children}</AccountsContext.Provider>)
}

export const useAccounts = () => {
  const context = useContext(AccountsContext);

  if (context === undefined) {
    throw new Error('`useAccounts` must be within a `AccountsProvider`');
  }
  return context;
}
