import axios, { AxiosError } from 'axios';
import { Auth } from '@aws-amplify/auth';
import { notify } from './NotificationService';

// Create axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    'Content-Type': 'application/json',
  }
})


// Interceptor to inject auth token
api.interceptors.request.use(
  async config => {
    let token = '';
    try {
      const session = await Auth.currentSession();
      token = session.getIdToken().getJwtToken();
    } catch (e) {
      token = '';
    }
    config.headers!.Authorization =  token;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
)

// Interceptor to handle errors
api.interceptors.response.use(
  response => response,
  (error: AxiosError<string> | Error) => {
    console.log('Error: ', error)
    let errorMessage = 'Unknown error. Please contact to support.'
    if (axios.isAxiosError(error)) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorMessage = error.response.data as string || errorMessage;
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        errorMessage = error.request.statusText || errorMessage;
      }
    } else {
      // Something happened in setting up the request that triggered an Error
      errorMessage = error.message;
    }
    notify('An error occured:', errorMessage, 'error');
    return Promise.reject(error);
  }
)


export default api;
