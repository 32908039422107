import { Suspense } from 'react';

import { useUser } from '../contexts/UserContext';
import NotAuthorized from '../pages/error-pages/NotAuthorized';
import Loading from '../shared/components/Loading';

const RequireAuth = ({ children }: { children: JSX.Element }) => {

  const user = useUser();

  if (user?.user)
    return <Suspense fallback={<Loading />}>{children}</Suspense>;
  return <NotAuthorized />;
}

export default RequireAuth;