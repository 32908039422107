import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Amplify } from '@aws-amplify/core';
import { ConfigProvider } from 'antd';

import App from './App';
import { UserProvider } from './contexts/UserContext';
import { AccountsProvider } from './contexts/AccountsContext';
import { OperationalTagsProvider } from './contexts/OperationalTagsContext';

// Configure Cognito auth
Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    mandatorySignIn: false,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin',
      ],
      redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN,
      redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGNOUT,
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
});

createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <ConfigProvider csp={{ nonce: process.env.REACT_APP_NONCE }}>
      <BrowserRouter>
        <UserProvider>
          <AccountsProvider>
            <OperationalTagsProvider>
              <App />
            </OperationalTagsProvider>
          </AccountsProvider>
        </UserProvider>
      </BrowserRouter>
    </ConfigProvider>
  </StrictMode>
);
