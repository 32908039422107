import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { buildMenu } from './menuItems';
import { AbilityContext } from '../Can';
import { useAbility } from '@casl/react';

const SiderMenu = () => {
  const ability = useAbility(AbilityContext);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const location = useLocation();

  // Control open keys of menu
  const onOpenChange = (openKeys: string[]) => {
    setOpenKeys(openKeys);
  };

  // Constract openKeys for menu
  useEffect(() => {
    let paths = [];
    for (var i = 1; i < location.pathname.length; i++) {
      if (location.pathname[i] === '/')
        paths.push(location.pathname.substring(0, i));
    }
    setOpenKeys(paths);
  }, [location]);

  const menu = buildMenu(ability);

  return (
    <>
      <Menu
        selectedKeys={[location.pathname]}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        mode="inline"
        items={menu}
      />
    </>
  );
};

export default SiderMenu;
