import { notification } from 'antd';
import { ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';

export const notify = (message: string, description: string, type?: 'info' | 'error') => {
  notification.open({
    message: message,
    description: description,
    icon: type === 'error' ? <ExclamationCircleOutlined  style={{ color: 'red' }} /> : <InfoCircleOutlined style={{color: 'blue'}} />,
    duration: 10
  });
}