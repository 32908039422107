import { useState } from 'react';
import { Layout, notification } from 'antd';
import SiderMenu from './main/SiderMenu';
import Toolbar from './main/Toolbar';
import PageRoutes from './main/Routes';
import { AbilityContext } from './Can';
import { buildAbility } from './ability';
import { useUser } from './contexts/UserContext';
import AppVersion from './main/AppVersion';

const { Sider, Footer } = Layout;

const App = () => {
  const user = useUser();
  const [collapsed, setCollapsed] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const onCollapse = () => setCollapsed(!collapsed);

  const permissions = user?.permissions || [];
  const ability = buildAbility(permissions);

  return (
    <AbilityContext.Provider value={ability}>
      {contextHolder}
      <Layout style={{ minHeight: '100vh' }}>
        <Toolbar collapsed={collapsed} onCollapse={onCollapse} />
        <Layout>
          <Sider
            width="270"
            theme="light"
            trigger={null}
            collapsible
            collapsed={collapsed}
          >
            <SiderMenu />
          </Sider>
          <Layout>
            <PageRoutes />
            <Footer style={{ textAlign: 'center' }}>
              <AppVersion />
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </AbilityContext.Provider>
  );
};

export default App;
