import { Ability, AbilityClass, createMongoAbility } from '@casl/ability';
import { BackendPermission } from './interfaces';
import { flatten } from 'lodash';

export type Actions = 'put' | 'get' | 'post';
export type Subjects =
  | 'backup'
  | 'restore'
  | 'resources'
  | 'service-catalog'
  | 'patching'
  | 'vmpowerstate'
  | 'tags';

const name = [
  'backup',
  'restore',
  'resources',
  'service-catalog',
  'patching',
  'vmpowerstate',
  'tags',
];

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;

interface ConvertedPermission {
  subject: Subjects;
  action: Actions[];
}

export const expandWildcards = (permissions: BackendPermission[]): BackendPermission[] => {
  const wildcards = permissions.filter(permission => permission.Subject.includes('*'));

  const expand = wildcards.map(wildcard => {
    const perns =  name.map(perm => {
      return {
        GroupName: wildcard.GroupName,
        Action: wildcard.Action,
        Subject: [perm],
      }
    })
    return perns;
  })

  return flatten(expand);
}

/*
 * This simple function converts a list of permissions from the backend
 *  to the form which is easier to use by the frontend.
 */
export const permissionsConverter = (
  permissions: BackendPermission[]
): ConvertedPermission[] => {

  const expanedPermissions = expandWildcards(permissions);
  const permissionsNoWildcards = permissions.filter(permission => !permission.Subject.includes('*'))

  const p = [...permissionsNoWildcards, ...expanedPermissions];

  const result = p.map((permission) => {
    const actions = permission.Action.map((action) => action.toLowerCase());
    const subjects = permission.Subject.map((subject) => {
      return {
        subject: subject as Subjects,
        action: actions as Actions[],
      };
    });
    return subjects;
  });

  return flatten(result);
};

export const buildAbility = (permissions: BackendPermission[]) => {
  const per = permissionsConverter(permissions);
  const ability = createMongoAbility<[Actions, Subjects]>(per);
  return ability;
};
