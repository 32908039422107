import packageVersion from '../../package.json';
import { Tooltip } from 'antd';

const AppVersion = () => {
  const commitSha = process.env.REACT_APP_COMMIT_SHA || 'dev';
  const buildDate = process.env.REACT_APP_BUILD_TIME || '';

  const version = `${buildDate} (${commitSha})`;
  return (
    <Tooltip title={version}>
      v{packageVersion.version} - Fujitsu ©{new Date().getFullYear()}
    </Tooltip>
  );
};

export default AppVersion;
