import { createElement } from 'react';
import { Layout, Space } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { ToolbarProps } from '../interfaces';
import Username from './Username';

const headerStyle = {
  backgroundColor: 'white',
  boxShadow: '0 2px 2px 0px rgba(0,0,0,.2)',
  zIndex: 10,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}

const { Header } = Layout;

const Toolbar = ({ collapsed, onCollapse }: ToolbarProps) => {
  return (
    <Header style={headerStyle}>
      <div style={{ display: 'flex', justifyContent: 'space-between', height: '100%' }}>
        <div style={{ marginRight: '20px' }}>
          {createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            style: { fontSize: '20px' },
            onClick: onCollapse,
          })}
        </div>
        <div style={{ marginRight: '20px' }}>
          <img style={{ width: 'auto', height: '100%' }} alt="" src="/fujitsu-logo.png"></img>
        </div>
        <div style={{ fontSize: '25px', fontWeight: '500' }}>{`FCMS UI [${process.env.REACT_APP_CUSTOMER}]`}</div>
      </div>
      <Space>
        <Username />
      </Space>
    </Header>
  );
};

export default Toolbar;
