import { Col, Row } from 'antd';
import {
  SettingOutlined,
  SaveOutlined,
  AppstoreOutlined,
} from '@ant-design/icons';
import Can from '../../Can';
import { Actions, Subjects } from '../../ability';
import Widget, { WidgetProps } from './components/Widget';

interface DashboardWidget {
  id: string;
  widget: WidgetProps;
  ability: {
    action: Actions;
    subject: Subjects;
  }
}

const widgets: DashboardWidget[]= [
  {
    id: 'patching',
    widget: {
      title: 'Patching',
      icon: <SettingOutlined />,
      links: [
        {
          label: 'Critical patching',
          path: '/patching/critical',
        },
      ],
    },
    ability: {
      subject: 'patching',
      action: 'get',
    }
  },
  {
    id: 'create-backup',
    widget: {
      title: 'Create backup',
      icon: <SaveOutlined />,
      links: [
        {
          label: 'On-demand backup VM',
          path: '/backup/vm/ondemand',
        },
        {
          label: 'On-demand backup Database',
          path: '/backup/database/ondemand',
        },
      ],
    },
    ability: {
      subject: 'backup',
      action: 'get',
    },
  },
  {
    id: 'restore-backup',
    widget: {
    title: 'Restore backup',
    icon: <SaveOutlined />,
    links: [
      {
        label: 'Restore VM backup',
        path: '/backup/vm/restore',
      },
      {
        label: 'Restore database backup',
        path: '/backup/database/restore',
      },
    ],
  },

    ability: {
      subject: 'restore',
      action: 'get',
    },
  },
  {
    id: 'bluprint-vm',
    widget: {
      title: 'Blueprint',
      icon: <AppstoreOutlined />,
      links: [
        {
          label: 'Provisioned products',
          path: '/blueprint/provisioned-products',
        },
        {
          label: 'Create new VM',
          path: '/blueprint/vm/createvm',
        },
        {
          label: 'Change VM power state',
          path: '/blueprint/vm/powerstate',
        },
      ],
    },
    ability: {
      subject: 'service-catalog',
      action: 'get',
    },
  },
  {
    id: 'blueprint-db',
    widget: {
      title: 'Blueprint',
      icon: <AppstoreOutlined />,
      links: [
        {
          label: 'Create RDS database',
          path: '/blueprint/database/createdb',
        },
        {
          label: 'Create VPC',
          path: '/blueprint/network/createvpc',
        },
        {
          label: 'Create S3 Bucket',
          path: '/blueprint/storage/creates3bucket',
        },
      ],
    },
    ability: {
      subject: 'service-catalog',
      action: 'get',
    },
  },
];

const Dashboard = () => {
  return (
    <>
      <Row gutter={[16, 16]}>
        {widgets.map((widget) => (
          <Can key={widget.id} I={widget.ability.action} a={widget.ability.subject}>
            <Col xs={24} md={12} xl={8}>
              <Widget
                title={widget.widget.title}
                icon={widget.widget.icon}
                links={widget?.widget.links || []}
              />
            </Col>
          </Can>
        ))}
      </Row>
    </>
  );
};

export default Dashboard;
