import { FC } from 'react';
import { Card, List, Space } from 'antd';
import { Link } from 'react-router-dom';

interface WidgetLink {
  label: string;
  path: string;
}

export interface WidgetProps {
  title: string;
  icon: React.ReactNode;
  links: WidgetLink[];
}

const Widget: FC<WidgetProps> = ({ title, icon, links }) => {
  return (
    <Card
      style={{ height: '300px' }}
      title={
        <Space>
          {icon} {title}
        </Space>
      }
    >
      <List
        dataSource={links}
        renderItem={(item) => (
          <List.Item>
            <Space>
              <Link to={item.path}>{item.label}</Link>
            </Space>
          </List.Item>
        )}
      />
    </Card>
  );
};

export default Widget;
