import { Button, Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import { LogoutOutlined, DownOutlined, UserOutlined, LoginOutlined } from '@ant-design/icons';
import { useUser } from '../contexts/UserContext';

const items: MenuProps['items'] = [
  { key: 'logout', icon: <LogoutOutlined />, label: 'Logout' }
];

const Username = () => {
  const user = useUser();

  const onClick: MenuProps['onClick'] = ({ key }) => {
    switch (key) {
      case 'logout':
        return user?.logout();
    }
  };

  if (user?.user) {
    return (
      <Dropdown menu={{ items, onClick }} trigger={['click']}>
        <Button type="link" onClick={(e) => e.preventDefault()}>
          <UserOutlined /> {user.user} <DownOutlined />
        </Button>
      </Dropdown>
    )
  }
  
  return (
    <Button type="primary" onClick={() => user?.login()} style={{ marginLeft: 'auto' }}>
      <LoginOutlined />Login
    </Button>
  )
}

export default Username;
