import { createContext, useState, useEffect, useMemo, useContext } from 'react';
import { ProviderProps, OperationalTagsContextType, OperationalTag, OperationalTagsOptions } from '../interfaces';
import { getOperationalTags } from '../services/CommonService';
import { useUser } from './UserContext';


const defaultValue: OperationalTagsContextType = {
  operationalTags: [],
  operationalTagsOptions: {
    Environment: [],
    CostCenter: [],
    ServiceLevel: [],
    Monitored: [],
    Backup: [],
    AutoPatch: [],
    PatchGroup: []
  },
  reloadTags: () => {}
}

export const OperationalTagsContext = createContext<OperationalTagsContextType>(defaultValue);

export const OperationalTagsProvider = ({children}: ProviderProps) => {

  const user = useUser();

  const [operationalTags, setOperationaTags] = useState<OperationalTag[]>([]);
  const [operationalTagsOptions, setOperationalTagsOptions] = useState<OperationalTagsOptions>(defaultValue.operationalTagsOptions);

  // A sanity check whether user has access to the /tags endpoint.
  // If not, the reloadTags function won't fetch data from /tags.
  const hasTagsAccess = (): boolean => {
    const hasAccess = user?.permissions.find((permission) =>
      permission.Subject.includes('tags')
    );

    if (hasAccess) {
      return true;
    }

    return false;
  };

  // Get data
  const reloadTags = () => {
    if (hasTagsAccess()) {
      getOperationalTags()
      .then(response => {
        setOperationaTags(response.data);
        setOperationalTagsOptions(response.data.filter(item => Array.isArray(item.value)).reduce((accumulator, item) => ({...accumulator, [item.name]: item.value}), {} as OperationalTagsOptions))
      })
        .catch(() => {})
    }
  }

  // Fetch data if user is logged in
  useEffect(() => {
    if (user?.user) reloadTags();
  }, [user?.user, user?.permissions]);

  const values = useMemo(() => ({ operationalTags, operationalTagsOptions, reloadTags }), [operationalTags, operationalTagsOptions]);

  // make sure other components can read this value
  return (<OperationalTagsContext.Provider value={values}>{children}</OperationalTagsContext.Provider>)
}

export const useOperationalTags = () => {
  const context = useContext(OperationalTagsContext);

  if (context === undefined) {
    throw new Error('`useOperationalTags` must be within a `OperationalTagsContextProvider`');
  }
  return context;
}
