import { createElement } from 'react';
import { Link } from 'react-router-dom';
import {
  SettingOutlined,
  AlertOutlined,
  SaveOutlined,
  FileTextOutlined,
  FileOutlined,
  EyeOutlined,
  HomeOutlined,
  FolderOpenOutlined,
  TagOutlined,
  AppstoreOutlined,
} from '@ant-design/icons';
import { MongoAbility } from '@casl/ability';

import type {
  MenuDividerType as RcMenuDividerType,
  MenuItemGroupType as RcMenuItemGroupType,
  MenuItemType as RcMenuItemType,
  SubMenuType as RcSubMenuType,
} from 'rc-menu/lib/interface';

export interface MenuItemType extends RcMenuItemType {
  danger?: boolean;
  icon?: React.ReactNode;
  title?: string;
}
export interface SubMenuType extends Omit<RcSubMenuType, 'children'> {
  icon?: React.ReactNode;
  theme?: 'dark' | 'light';
  children: ItemType[];
}
export interface MenuItemGroupType
  extends Omit<RcMenuItemGroupType, 'children'> {
  children?: ItemType[];
  key?: React.Key;
}
export interface MenuDividerType extends RcMenuDividerType {
  dashed?: boolean;
  key?: React.Key;
}
export declare type ItemType =
  | MenuItemType
  | SubMenuType
  | MenuItemGroupType
  | MenuDividerType
  | null;

const e = createElement;

const buildMenuTags = (ability: MongoAbility): ItemType => {
  const menuTags = {
    key: '/tags',
    icon: e(TagOutlined),
    label: e(Link, { to: '/tags' }, 'Tags'),
  };

  if (ability.can('get', 'resources')) {
    return menuTags;
  }

  return null;
};

const buildMenuPatching = (ability: MongoAbility): ItemType => {
  const menuPatching = {
    key: '/patching',
    icon: e(SettingOutlined),
    label: 'Patching',
    children: [
      {
        key: '/patching/critical',
        icon: e(AlertOutlined),
        label: e(Link, { to: '/patching/critical' }, 'Critical'),
      },
    ],
  };

  if (ability.can('get', 'patching')) {
    return menuPatching;
  }

  return null;
};

const buildMenuBackup = (ability: MongoAbility): ItemType => {
  const menuBackup = {
    key: '/backup',
    icon: e(SaveOutlined),
    label: 'Backup',
    children: [
      {
        key: '/backup/vm',
        icon: e(FolderOpenOutlined),
        label: 'VM',
        children: [
          ability.can('get', 'backup') && {
            key: '/backup/vm/ondemand',
            icon: e(FileOutlined),
            label: e(Link, { to: '/backup/vm/ondemand' }, 'On-demand'),
          },
          ability.can('get', 'restore') && {
            key: '/backup/vm/restore',
            icon: e(FileOutlined),
            label: e(Link, { to: '/backup/vm/restore' }, 'Restore'),
          },
        ],
      },
      {
        key: '/backup/database',
        icon: e(FolderOpenOutlined),
        label: 'Database',
        children: [
          ability.can('get', 'backup') && {
            key: '/backup/database/ondemand',
            icon: e(FileOutlined),
            label: e(Link, { to: '/backup/database/ondemand' }, 'On-demand'),
          },
          ability.can('get', 'restore') && {
            key: '/backup/database/restore',
            icon: e(FileOutlined),
            label: e(Link, { to: '/backup/database/restore' }, 'Restore'),
          },
        ],
      },
      {
        key: '/backup/storage',
        icon: e(FolderOpenOutlined),
        label: 'Storage',
        children: [
          ability.can('get', 'backup') && {
            key: '/backup/storage/ondemand',
            icon: e(FileOutlined),
            label: e(Link, { to: '/backup/storage/ondemand' }, 'On-demand'),
          },
          ability.can('get', 'restore') && {
            key: '/backup/storage/restore',
            icon: e(FileOutlined),
            label: e(Link, { to: '/backup/storage/restore' }, 'Restore'),
          },
        ],
      },
    ],
  };

  if (ability.can('get', 'backup') || ability.can('get', 'restore')) {
    return menuBackup;
  }

  return null;
};

const buildMenuMonitoring = (ability: MongoAbility): ItemType => {
  const menuMonitoring = {
    key: '/monitoring',
    icon: e(EyeOutlined),
    label: e(Link, { to: '/monitoring' }, 'Monitoring'),
  };

  return menuMonitoring;
};

const buildMenuLogging = (ability: MongoAbility): ItemType => {
  const menuLogging = {
    key: '/logging',
    icon: e(FileTextOutlined),
    label: e(Link, { to: '/logging' }, 'Logging'),
  };

  return menuLogging;
};

const buildMenuBlueprint = (ability: MongoAbility): ItemType => {
  const menuBlueprint = {
    key: '/blueprint',
    icon: e(AppstoreOutlined),
    label: 'Blueprint',
    children: [
      {
        key: '/blueprint/provisioned-products',
        icon: e(FileOutlined),
        label: e(
          Link,
          { to: '/blueprint/provisioned-products' },
          'Provisioned products'
        ),
      },
      {
        key: '/blueprint/vm',
        icon: e(FolderOpenOutlined),
        label: 'VM',
        children: [
          {
            key: '/blueprint/vm/createvm',
            icon: e(FileOutlined),
            label: e(Link, { to: '/blueprint/vm/createvm' }, 'Create VM'),
          },
          ability.can('put', 'vmpowerstate') && {
            key: '/blueprint/vm/powerstate',
            icon: e(FileOutlined),
            label: e(Link, { to: '/blueprint/vm/powerstate' }, 'Power State'),
          },
        ],
      },
      {
        key: '/blueprint/database',
        icon: e(FolderOpenOutlined),
        label: 'Database',
        children: [
          {
            key: '/blueprint/database/createdb',
            icon: e(FileOutlined),
            label: e(
              Link,
              { to: '/blueprint/database/createdb' },
              'Create RDS database'
            ),
          },
          {
            key: '/blueprint/database/createdynamodb',
            icon: e(FileOutlined),
            label: e(
              Link,
              { to: '/blueprint/database/createdynamodb' },
              'Create DynamoDB table'
            ),
          },
        ],
      },
      {
        key: '/blueprint/network',
        icon: e(FolderOpenOutlined),
        label: 'Network',
        children: [
          {
            key: '/blueprint/network/createvpc',
            icon: e(FileOutlined),
            label: e(
              Link,
              { to: '/blueprint/network/createvpc' },
              'Create VPC'
            ),
          },
        ],
      },
      {
        key: '/blueprint/storage',
        icon: e(FolderOpenOutlined),
        label: 'Storage',
        children: [
          {
            key: '/blueprint/storage/creates3bucket',
            icon: e(FileOutlined),
            label: e(
              Link,
              { to: '/blueprint/storage/creates3bucket' },
              'Create S3 bucket'
            ),
          },
          {
            key: '/blueprint/storage/createefs',
            icon: e(FileOutlined),
            label: e(
              Link,
              { to: '/blueprint/storage/createefs' },
              'Create EFS'
            ),
          },
          {
            key: '/blueprint/storage/createfsx',
            icon: e(FileOutlined),
            label: e(
              Link,
              { to: '/blueprint/storage/createfsx' },
              'Create FSx'
            )
          }
        ],
      },
      {
        key: '/blueprint/website',
        icon: e(FolderOpenOutlined),
        label: 'Website',
        children: [
          {
            key: '/blueprint/website/createBeanstalk',
            icon: e(FileOutlined),
            label: e(
              Link,
              { to: '/blueprint/website/createBeanstalk' },
              'Create Beanstalk'
            ),
          },
          {
            key: '/blueprint/website/createStaticWebsite',
            icon: e(FileOutlined),
            label: e(
              Link,
              { to: '/blueprint/website/createStaticWebsite' },
              'Create Static Website'
            ),
          },
        ],
      },
    ],
  };

  if (ability.can('get', 'service-catalog')) {
    return menuBlueprint;
  }

  return null;
};

export const buildMenu = (ability: MongoAbility): ItemType[] => {
  const menu = [
    {
      type: 'group',
      key: 'general',
      label: 'General',
      children: [
        { type: 'divider' },
        {
          key: '/',
          icon: e(HomeOutlined),
          label: e(Link, { to: '/' }, 'Home'),
        },
        buildMenuTags(ability),
      ],
    },
    {
      type: 'group',
      key: 'solutions',
      label: 'Solutions',
      children: [
        { type: 'divider' },
        buildMenuPatching(ability),
        buildMenuBackup(ability),
        // buildMenuMonitoring(ability), // Monitoring is not supported yet
        // buildMenuLogging(ability), // Logging is not supported yet
        buildMenuBlueprint(ability),
      ],
    },
  ];

  return menu;
};
