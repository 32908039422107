import { Result, Button } from 'antd';
import { LoginOutlined } from '@ant-design/icons';
import { useUser } from '../../contexts/UserContext';

const NotAuthorized = () => {

  const user = useUser();

  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={<Button type="primary" onClick={() => user?.login()}><LoginOutlined />Login</Button>}
    />
  )
};

export default NotAuthorized;