import { createContext, useState, useEffect, useContext, useMemo } from 'react';
import { Auth } from '@aws-amplify/auth';
import {
  ProviderProps,
  UserContextType,
  BackendPermission,
} from '../interfaces';
import { getPermissions } from '../services/PermissionsService';
import { setAuthorizerHeaders } from '../services/authorizerHeaders';

export const UserContext = createContext<UserContextType | null>(null);

const storeHeadersData = (user: any) => {
  setAuthorizerHeaders({
    id: user.username,
    poolid: user.pool.userPoolId,
    email: user.attributes.email,
  });
};

export const UserProvider = ({ children }: ProviderProps) => {
  const [user, setUser] = useState<string | undefined>(undefined);
  const [permissions, setPermissions] = useState<BackendPermission[]>([]);

  // attempt to fetch the info of the user that was already logged in
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        storeHeadersData(user);
        setUser(user.attributes.email);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (user) {
      getPermissions()
        .then((response) => setPermissions(response.data))
        .catch(() => {});
    }
  }, [user]);

  const login = () => Auth.federatedSignIn();

  const logout = () => Auth.signOut();

  const values = useMemo(
    () => ({ user, permissions, login, logout }),
    [user, permissions]
  );

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('`useUser` must be within a `UserProvider`');
  }
  return context;
};
