import { AxiosResponseHeaders } from 'axios';

interface AuthorizerHeaders {
  id: string;
  poolid: string;
  email: string;
}

interface AuthorizerHeadersStore {
  id: string | undefined;
  poolid: string | undefined;
  email: string | undefined;
}

let authorizerHeaders: AuthorizerHeadersStore = {
  id: undefined,
  poolid: undefined,
  email: undefined,
};

export const setAuthorizerHeaders = (headers: AuthorizerHeaders) => {
  authorizerHeaders = {
    id: headers.id,
    poolid: headers.poolid,
    email: headers.email,
  };
};


export const getAuthorizerHeaders = (): AxiosResponseHeaders => {
  if (
    authorizerHeaders.id === undefined ||
    authorizerHeaders.poolid === undefined ||
    authorizerHeaders.email === undefined
  ) {
    throw Error('Authorizer headers are uninitialized');
  }

  return {
    id: authorizerHeaders.id,
    poolid: authorizerHeaders.poolid,
    email: authorizerHeaders.email,
  }

  //  return authorizerHeaders;
};
