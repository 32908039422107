import api from './API';
import {
  Resource,
  Ou,
  OperationalTag,
  UpdateResourceBody,
} from '../interfaces';
import { getAuthorizerHeaders } from './authorizerHeaders';

export function getAccounts() {
  return api.get<Ou[]>('/organization/accounts');
}

export function getRegions() {
  return api.get<string[]>('/organization/regions');
}

export function getResources(account: string, region: string, type: string) {
  const headers = getAuthorizerHeaders();
  return api.get<Resource[]>(`/resources/${account}/${region}/${type}`, {
    headers,
  });
}

export function updateResource(
  account: string,
  region: string,
  type: string,
  body: UpdateResourceBody
) {
  const headers = getAuthorizerHeaders();
  return api.put<string>(`/resources/${account}/${region}/${type}`, body, {
    headers,
  });
}

export function getOperationalTags() {
  const headers = getAuthorizerHeaders();
  return api.get<OperationalTag[]>('/tags', { headers });
}
